/* stylelint-disable */
/* stylelint-enable */
/* stylelint-disable wrap-rem-on-px/wrap-rem-on-px */
/* stylelint-enable wrap-rem-on-px/wrap-rem-on-px */
/*
USAGE:
font-size: fluid(12, 62); : from rem(12)@375px to  rem(62)@1440px
gap: fluid(10, 33, 992);  : from rem(10)@991px to  rem(33)@1440px
margin-right: fluid(32, 65, 320, 2560);  : from rem(32)@320px to  rem(65)@2560px
*/
/* stylelint-disable */
/* stylelint-enable */
/* Font */
/* Base palette */
/* Brand palette */
/* Theme */
/* State palette */
.section--breadcrumb {
  position: relative;
}
.section--breadcrumb::after {
  position: absolute;
  bottom: 0;
  left: 50%;
  width: calc(100% - 0rem);
  height: 1px;
  background-color: #DCDCDC;
  content: "";
  opacity: 0.3;
  transform: translateX(-50%);
}
@media screen and (min-width:991px) {
  .section--breadcrumb::after {
    width: calc(100% - 0rem);
  }
}

.qpac.breadcrumb {
  padding-top: 1.5rem;
}
.qpac.breadcrumb ol {
  display: flex;
  padding-bottom: 1rem;
}
.qpac.breadcrumb li {
  display: flex;
  padding-right: 0.625rem;
  color: #00FFAE;
  font-size: 0.75rem;
  font-weight: 400;
  line-height: 120%;
  text-transform: uppercase;
}
.qpac.breadcrumb li:not(:last-of-type)::after {
  padding-left: 0.625rem;
  content: "/";
}
.qpac.breadcrumb span, .qpac.breadcrumb a {
  color: #00FFAE;
}
.qpac.breadcrumb .breadcrumb__active a {
  text-decoration: underline;
}
.qpac.breadcrumb .breadcrumb__active a.is-clickable:hover {
  color: #fff;
  transition: all 0.3s ease-in-out;
}